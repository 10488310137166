<template>
  <div class="md:flex md:justify-around">
    <div class="mt-4">
      <h1 class="font-semibold">Subscription Plan</h1>
      <SubscriptionPlanOption
        planName="Month"
        planLabel="Month"
        :planPrice="parseFloat(prices.monthly_price)"
        :isSelected="plan === 'Month'"
        :loading="gettingPrices"
        @selectPlan="selectPlan"
      />
      <SubscriptionPlanOption
        planName="Semester"
        planLabel="Six months"
        :planPrice="parseFloat(prices.semiannual_price)"
        :isSelected="plan === 'Semester'"
        :loading="gettingPrices"
        @selectPlan="selectPlan"
      />
      <SubscriptionPlanOption
        planName="Year"
        planLabel="Annual"
        :planPrice="parseFloat(prices.annual_price)"
        :isSelected="plan === 'Year'"
        :loading="gettingPrices"
        @selectPlan="selectPlan"
      />
      <PlayerCountSelector
        :players="players"
        :price="parseFloat(price)"
        @update:players="(value) => (players = value)"
      />
      <form id="stripe" @submit.prevent="handlePaymentSubmission" class="mt-3">
        <h5 class="font-semibold">Payment Details</h5>
        <div class="mt-2">
          <input
            class="mt-1 block w-full py-2 px-4 bg-grey-steps"
            type="text"
            placeholder="Name on card"
            :disabled="freeCost"
            required
          />
        </div>
        <div class="">
          <div class="my-2 border p-4" ref="cardElement"></div>
        </div>
      </form>
    </div>
    <div class="md:w-6/12 mt-4">
      <p class="font-semibold">Discount Code</p>
      <div class="flex justify-between mt-2">
        <input
          style="background-color: #ecf0f5"
          v-model="discountCode"
          id="promotion-code"
          class="w-6/12 p-2"
          type="text"
          placeholder="code"
          required
        />
        <button
          id="b-pay"
          class="w-2/5 text-white p-1"
          style="background-color: #1d2e48"
          v-on:click="applyDiscountCode"
          :disabled="!discountCode || checkDiscountCode"
        >
          <em v-if="checkDiscountCode" class="fa fa-spinner fa-spin"></em>
          Apply
        </button>
      </div>
      <h6 v-if="discountCodeErrorText" class="text-red-600 mt-1">
        {{ discountCodeErrorText }}
      </h6>
      <div class="flex mt-4">
        <p class="font-semibold">Organization Code</p>
        <span class="info-icon ml-2" title="Your organization code"
          >&#x1F6C8;</span
        >
      </div>
      <div class="flex justify-between mt-2">
        <input
          style="background-color: #ecf0f5"
          v-model="facilityCode"
          id="organization-code"
          class="w-6/12 p-2"
          type="text"
          placeholder="code"
          required
        />
        <button
          id="b-pay"
          class="w-2/5 text-white p-1"
          style="background-color: #1d2e48"
          v-on:click="verifyFacilityCode"
          :disabled="!facilityCode || checkFacilityCode"
        >
          <em v-if="checkFacilityCode" class="fa fa-spinner fa-spin"></em>
          Verify
        </button>
      </div>
      <OrderSummary
        :players="players"
        :subTotal="parseFloat(subTotal)"
        :totalDiscount="parseFloat(totalDiscount)"
        :percentageDiscount="parseFloat(percentageDiscount)"
        :totalPrice="parseFloat(totalPrice)"
      />
      <button
        id="b-pay"
        form="stripe"
        :disabled="processingPayment || gettingPrices"
        class="w-full mt-4 text-color font-semibold bg-mindaro p-2"
        type="submit"
      >
        <em v-if="processingPayment" class="fa fa-spinner fa-spin"></em>
        {{ freeCost || totalDiscount === price ? "Start Free" : "Pay" }}
      </button>
    </div>
    <Modal
      :show="showModal"
      @confirm="handleConfirm"
      :isConfirmDisabled="false"
      width="300px"
    >
      <template v-slot:header>
        <h2 class="font-bold mb-4">{{ modalText }}</h2>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  createSubscription,
  createB2CUser,
  getStripeDiscountCode,
  getPlanPrices,
} from "@/services/stripe/stripe.js";
import {
  locationPrices,
  validateFacilityCode,
} from "@/services/location/location.js";
import { triggerPurchaseEvent } from "@/services/gtmTracker/gtmTracker.js";
import Modal from "@/components/general/ConfirmationModal.vue";
import { useStripe } from "@/composables/useStripe";
import { useToast } from "vue-toastification";

import PlayerCountSelector from "@/components/checkout/PlayerCountSelector.vue";
import SubscriptionPlanOption from "@/components/checkout/SubscriptionPlanOption.vue";
import OrderSummary from "@/components/checkout/OrderSummary.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

// eslint-disable-next-line no-undef
const {
  cardElement,
  cardObj,
  stripeValidationError,
  initializateStripeElements,
  createPaymentMethod,
} = useStripe();
const toast = useToast();

const players = ref(1);
const customerId = ref(null);
const email = ref("");
const firstName = ref("");
const lastName = ref("");
const nameOnCard = ref("");
const price = ref(0);
const subTotal = ref(0);
const totalDiscount = ref(0);
const percentageDiscount = ref(0);
const totalPrice = ref(0);
const processingPayment = ref(false);
const plan = ref("Month");
const prices = ref({});
const freeCost = ref(false);
const facilityName = ref("");
const facilityCode = ref("");
const validatedFacilityCode = ref("");
const checkFacilityCode = ref(false);
const isValidFacilityCode = ref(false);
const showModal = ref(false);
const modalText = ref("");
const discountCode = ref("");
const validatedDiscountCode = ref("");
const checkDiscountCode = ref(false);
const isDynamicDiscount = ref(false);
const discountCodeErrorText = ref("");
const isFreeTrial = ref(false);
const gettingPrices = ref(false);

onMounted(async () => {
  const {
    customerId: id,
    email: mail,
    firstName: givenName,
    lastName: surName,
    isFreeTrial: trial,
    locationName,
  } = route.query;

  customerId.value = id;
  email.value = mail;
  firstName.value = givenName;
  lastName.value = surName;
  isFreeTrial.value = trial;
  facilityName.value = locationName;

  if (facilityName.value) {
    await getLocationPrices(null, facilityName.value);
  } else {
    await getDefaultPrices();
  }

  initializateStripeElements();
});

async function handlePaymentSubmission() {
  processingPayment.value = true;
  try {
    if (await isFreeOrDiscounted()) return;

    if (players.value < 1) return;

    const paymentMethod = await createPaymentMethod({ name: nameOnCard.value });

    if (!paymentMethod) {
      showError(stripeValidationError.value);
      return;
    }

    await subscriptionRequest(paymentMethod.id);

    if (redirectIfFreeTrial()) return;

    redirectToPlayerRegistration(isValidFacilityCode.value ? "B2B" : "B2C");
  } catch (e) {
    showError(e.message);
  } finally {
    processingPayment.value = false;
  }
}

async function isFreeOrDiscounted() {
  if (freeCost.value || totalDiscount.value === price.value) {
    await createB2CUser({
      customerId: customerId.value,
      quantity: players.value,
      coupon: freeCost.value ? "Free-cost" : validatedDiscountCode.value,
      facilityCode: isValidFacilityCode.value
        ? validatedFacilityCode.value
        : "",
    });
    if (redirectIfFreeTrial()) return true;
    redirectToPlayerRegistration("free-cost");
    return true;
  }
  return false;
}

function redirectIfFreeTrial() {
  if (checkFreeTrial()) {
    router.push({ name: "Home" });
    return true;
  }
  return false;
}

function redirectToPlayerRegistration(registrationMethod) {
  router.push({
    name: "Register players",
    query: {
      n: players.value,
      registrationMethod,
    },
  });
}

function checkFreeTrial() {
  if (isFreeTrial.value) {
    if (players.value === 1) {
      store.commit("user/setIsFreeTrialExpired", false);
      return true;
    } else {
      players.value = players.value - 1;
    }
  }
  return false;
}

async function subscriptionRequest(paymentMethodId) {
  const priceId = getPriceId(plan.value);
  let couponIdToSend = validatedDiscountCode.value;
  let discountPercentageToSend = percentageDiscount.value;

  if (
    (isDynamicDiscount.value && plan.value !== "Month") ||
    discountPercentageToSend === 0
  ) {
    couponIdToSend = "";
    discountPercentageToSend = 0;
  }

  const response = await createSubscription({
    customerId: customerId.value,
    paymentMethodId,
    priceId,
    quantity: players.value,
    couponId: couponIdToSend,
    discountPercentage: discountPercentageToSend !== 0 ? discountPercentageToSend.toFixed(2) : 0,
    proration: false,
    facilityCode: isValidFacilityCode.value ? validatedFacilityCode.value : "",
    isFreeTrial: isFreeTrial.value,
  });

  handleGTMEvent(response.data.subscription.id);
}

function handleGTMEvent(subscriptionId) {
  const subscriptionData = {
    id: subscriptionId,
    paymentAmount: totalPrice.value.toFixed(2),
    suscriptionPlan: plan.value,
    players: players.value,
    discountCode: validatedDiscountCode.value,
    organizationCode: isValidFacilityCode.value ? validatedFacilityCode.value : "",
    totalBeforeDiscount: subTotal.value.toFixed(2),
    discountAmount: totalDiscount.value.toFixed(2),
  };

  const userDetails = {
    email: email.value,
    firstName: firstName.value,
    lastName: lastName.value,
    category: isValidFacilityCode.value ? "B2B" : "B2C",
  }

  triggerPurchaseEvent(
    subscriptionData,
    userDetails,
  );
}

function getPriceId(plan) {
  switch (plan) {
    case "Month":
      return prices.value.stripe_price_id_monthly;
    case "Semester":
      return prices.value.stripe_price_id_semiannual;
    case "Year":
      return prices.value.stripe_price_id_annual;
  }
}

async function getLocationPrices(facilityCode = null, locationName = null) {
  try {
    if (facilityCode && locationName) {
      throw new Error("Only one of facilityCode or locationName should be provided.");
    }
    const { data } = await locationPrices(facilityCode, locationName);
    prices.value = data;
    freeCost.value = data.location.free_cost;
    selectPlan(plan.value);
    recalculateTotals();
  } catch (error) {
    throw new Error(error);
  }
}

async function getDefaultPrices() {
  try {
    gettingPrices.value = true;
    const data = await getPlanPrices();
    prices.value = data;
    selectPlan(plan.value);
    recalculateTotals();
  } catch (error) {
    throw new Error(error);
  } finally {
    gettingPrices.value = false;
  }
}

function recalculateTotals() {
  subTotal.value = calculateTotalPrice(price.value, players.value);
  totalPrice.value = calculateTotalPrice(price.value, players.value);
}

function selectPlan(planSelected) {
  const planPrices = {
    Month: prices.value.monthly_price,
    Semester: prices.value.semiannual_price,
    Year: prices.value.annual_price,
  };

  if (planPrices[planSelected]) {
    percentageDiscount.value = 0;
    totalDiscount.value = 0;
    updatePricing(planSelected, planPrices[planSelected]);
  }
}

function updatePricing(planSelected, pricePerPlan) {
  const formattedPrice = Number(pricePerPlan);
  price.value = formattedPrice;
  totalPrice.value = calculateTotalPrice(formattedPrice, players.value);
  subTotal.value = totalPrice.value;
  plan.value = planSelected;
}

function calculateTotalPrice(price, playersCount) {
  return (price * playersCount);
}

async function verifyFacilityCode() {
  try {
    checkFacilityCode.value = true;
    const { data: isValidCode } = await validateFacilityCode(
      facilityCode.value
    );

    if (isValidCode) {
      validatedFacilityCode.value = facilityCode.value;
      await getLocationPrices(facilityCode.value);
      isValidFacilityCode.value = true;
      modalText.value = "Valid code";
    } else {
      getDefaultPrices();
      freeCost.value = false;
      isValidFacilityCode.value = false;
      modalText.value = "Invalid code";
    }

    selectPlan(plan.value);

    recalculateTotals();
    if (discountCode.value) {
      await applyDiscountCode();
    }

    showModal.value = true;
  } catch (error) {
    showModal.value = true;
    modalText.value = "Error verifying code";
  } finally {
    checkFacilityCode.value = false;
  }
}

function showError(errorMess) {
  toast.error(errorMess);
}

function handleConfirm() {
  showModal.value = false;
}

async function applyDiscountCode() {
  checkDiscountCode.value = true;

  discountCodeErrorText.value = "";

  if (!discountCode.value) return;

  try {
    const discountData = await getStripeDiscountCode(discountCode.value);
    validatedDiscountCode.value = discountCode.value;
    isDynamicDiscount.value = discountData.is_dynamic;

    totalDiscount.value = 0;
    percentageDiscount.value = 0;

    if (
      plan.value === "Month" &&
      discountData.is_dynamic &&
      discountData.expected_price != null &&
      discountData.expected_price <= price.value
    ) {
      const dynamicDiscount =
        ((price.value - discountData.expected_price) / price.value) * 100;
      const dynamicDiscountRounded = Number(dynamicDiscount);
      const dynamicPriceOff = (subTotal.value * dynamicDiscount) / 100;
      const dynamicPriceOffRounded = Number(dynamicPriceOff);

      applyDiscount(dynamicPriceOffRounded, dynamicDiscountRounded);
    } else if (!discountData.is_dynamic) {
      const staticDiscount = Number(discountData.discount_percentage);
      const staticPriceOff = (subTotal.value * staticDiscount) / 100;
      
      applyDiscount(staticPriceOff, staticDiscount);
    }
  } catch (error) {
    discountCodeErrorText.value = "The discount code is invalid or inactive!";   
    resetDiscount();
  } finally {
    checkDiscountCode.value = false;
  }
}

function applyDiscount(discountValue, discountPercentage) {
  totalDiscount.value = discountValue;
  percentageDiscount.value = discountPercentage;
  totalPrice.value = (subTotal.value - discountValue);
}

function resetDiscount() {
  validatedDiscountCode.value = "";
  totalDiscount.value = 0;
  percentageDiscount.value = 0;
  totalPrice.value = subTotal.value;
}

watch(players, (newValue) => {
  if (newValue < 1) {
    newValue = 1;
    players.value = 1;
  }

  subTotal.value = (price.value * newValue);
  totalPrice.value = subTotal.value;

  if (discountCode.value) {
    applyDiscountCode();
  }
});

watch(freeCost, (newValue) => {
  if (cardObj.value) {
    cardObj.value.update({ disabled: newValue });
  }
});

watch(discountCode, (newValue) => {
  discountCodeErrorText.value = "";
  if (newValue) {
    resetDiscount();
  }
});
</script>

<style lang="scss">
#b-pay:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #1d2e48;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  border: 0.4em solid #1d2e48;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;

  + .contendero {
    border-color: green;
  }
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
