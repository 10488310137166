<template>
  <div class="md:flex md:justify-around">
    <div :class="!distinctPriceTiers ? 'mt-3' : 'mt-6'">
      <div class="mb-18" v-if="distinctPriceTiers">
        <h1 class="font-semibold">Promotion</h1>
        <div class="md:flex justify-between gap-x-4">
          <PlanCard
            planName="Basic"
            :range="`1-${prices.min_users_tier_2 - 1}`"
            :price="parseFloat(prices.price_tier_1)"
            :iconSrc="require('@/assets/icons/basic-plan-icon.svg')"
            :selected="tier1Selected"
          />
          <PlanCard
            planName="Medium"
            :range="`${prices.min_users_tier_2}-${prices.max_users_tier_2}`"
            :price="parseFloat(prices.price_tier_2)"
            :discount="parseFloat(prices.discount_tier_2)"
            :iconSrc="require('@/assets/icons/medium-plan-icon.svg')"
            :selected="tier2Selected"
          />
          <PlanCard
            planName="Pro"
            :range="`${prices.max_users_tier_2}+`"
            :price="parseFloat(prices.price_tier_3)"
            :discount="parseFloat(prices.discount_tier_3)"
            :iconSrc="require('@/assets/icons/pro-plan-icon.svg')"
            :selected="tier3Selected"
          />
        </div>
      </div>
      <PlayerCountSelector
        :players="players"
        :price="parseFloat(price)"
        @update:players="(value) => (players = value)"
      />
      <div
        v-if="loadingPaymentInfo"
        class="flex justify-center items-center h-20"
      >
        <em class="fa fa-spinner fa-spin text-xl"></em>
      </div>
      <form
        v-else
        id="stripe"
        @submit.prevent="handlePaymentSubmission"
        class="mt-3"
      >
        <div v-if="paymentMethodId && paymentMethodInfo">
          <div class="mt-3 flex items-center justify-between gap-4 border p-3">
            <div class="flex items-center gap-4">
              <span class="text-lg font-semibold capitalize">
                {{ paymentMethodInfo?.card?.brand }}
              </span>
              <span class="text-lg font-semibold">
                ••••{{ paymentMethodInfo?.card?.last4 }}
              </span>
            </div>
            <button @click="resetPaymentMethod" class="text-blue-600 f">
              Change
            </button>
          </div>
        </div>
        <div v-else>
          <h5 class="font-semibold">Payment Details</h5>
          <div class="mt-2">
            <input
              v-model="nameOnCard"
              class="mt-1 block w-full py-2 px-4 bg-grey-steps"
              type="text"
              placeholder="Name on card"
              required
            />
          </div>
          <div>
            <div class="my-2 border p-4" ref="cardElement"></div>
          </div>
        </div>
      </form>
    </div>
    <div class="md:w-6/12 mt-6">
      <p class="font-semibold">Discount Code</p>
      <div class="flex justify-between mt-2">
        <input
          style="background-color: #ecf0f5"
          v-model="discountCode"
          id="promotion-code"
          class="w-6/12 p-2"
          type="text"
          placeholder="code"
          required
        />
        <button
          id="b-pay"
          class="w-2/5 text-white p-1"
          style="background-color: #1d2e48"
          v-on:click="applyDiscountCode"
          :disabled="!discountCode || checkDiscountCode"
        >
          Apply
        </button>
      </div>
      <h6 v-if="discountCodeErrorText" class="text-red-600 mt-1">
        {{ discountCodeErrorText }}
      </h6>
      <OrderSummary
        :players="players"
        :subTotal="parseFloat(subTotal)"
        :totalDiscount="parseFloat(totalDiscount)"
        :percentageDiscount="parseFloat(percentageDiscount)"
        :totalPrice="parseFloat(totalPrice)"
      />
      <button
        id="b-pay"
        form="stripe"
        :disabled="processingPayment"
        class="w-full mt-4 text-color font-semibold bg-mindaro p-2"
        type="submit"
      >
        <em v-if="processingPayment" class="fa fa-spinner fa-spin"></em>
        Pay
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, computed } from "vue";
import {
  buySeats,
  getTierPrices,
  getStripeDiscountCode,
  getDefaultPaymentMethod,
} from "@/services/stripe/stripe.js";
import { triggerPurchaseEvent } from "@/services/gtmTracker/gtmTracker.js";
import { useStore } from "vuex";
import { useStripe } from "@/composables/useStripe";
import { useToast } from "vue-toastification";

import PlayerCountSelector from "@/components/checkout/PlayerCountSelector.vue";
import OrderSummary from "@/components/checkout/OrderSummary.vue";
import PlanCard from "@/components/checkout/PlanCard.vue";

// eslint-disable-next-line no-undef
const {
  cardElement,
  stripeValidationError,
  initializateStripeElements,
  createPaymentMethod,
} = useStripe();
const toast = useToast();
const store = useStore();
const user = computed(() => store.getters["user/getUserData"]);

const players = ref(1);
const prices = ref({});
const gettingPrices = ref(false);
const subTotal = ref(0);
const totalPrice = ref(0);
const price = ref(0);
const priceId = ref("");
const checkDiscountCode = ref(false);
const discountCode = ref("");
const discountCodeErrorText = ref("");
const validatedDiscountCode = ref("");
const isDynamicDiscount = ref(false);
const totalDiscount = ref(0);
const percentageDiscount = ref(0);
const tier1Selected = ref(true);
const tier2Selected = ref(false);
const tier3Selected = ref(false);
const nameOnCard = ref("");
const processingPayment = ref(false);
const paymentMethodInfo = ref(null);
const paymentMethodId = ref(null);
const loadingPaymentInfo = ref(true);
const distinctPriceTiers = computed(() => {
  if (!prices.value) return false;

  return (
    prices.value.price_tier_1 !== prices.value.price_tier_2 &&
    prices.value.price_tier_2 !== prices.value.price_tier_3 &&
    prices.value.price_tier_1 !== prices.value.price_tier_3
  );
});

const emit = defineEmits(["confirm"]);

onMounted(async () => {
  await getDefaultPrices();
  await retrieveDefaultPaymentMethod();
  if (!paymentMethodId.value) {
    await nextTick();
    initializateStripeElements();
  }
});

async function getDefaultPrices() {
  try {
    gettingPrices.value = true;

    const data = await getTierPrices();
    prices.value = data;

    setTierPrice("tier_1");
    recalculateTotals();
  } catch (error) {
    throw new Error(error);
  } finally {
    gettingPrices.value = false;
  }
}

async function retrieveDefaultPaymentMethod() {
  try {
    const data = await getDefaultPaymentMethod();
    paymentMethodInfo.value = data;
    paymentMethodId.value = data.id;
    if (!data) {
      clearPaymentMethod();
    }
  } catch (error) {
    clearPaymentMethod();
  } finally {
    loadingPaymentInfo.value = false;
  }
}

function clearPaymentMethod() {
  paymentMethodId.value = null;
  paymentMethodInfo.value = null;
}

function setTierPrice(tier) {
  price.value = prices.value[`price_${tier}`];
  priceId.value = prices.value[`stripe_price_id_${tier}`];

  tier1Selected.value = tier === "tier_1";
  tier2Selected.value = tier === "tier_2";
  tier3Selected.value = tier === "tier_3";
}

async function handlePaymentSubmission() {
  processingPayment.value = true;

  try {
    if (players.value < 1) return;

    if (!paymentMethodId.value) {
      const paymentMethod = await createPaymentMethod({
        name: nameOnCard.value,
      });

      if (!paymentMethod) {
        handleMessage(stripeValidationError.value, true);
        return;
      }

      paymentMethodId.value = paymentMethod.id;
    }

    await subscriptionRequest(paymentMethodId.value);
    emit("confirm");
  } catch (e) {
    handleMessage(e.message, true);
  } finally {
    processingPayment.value = false;
  }
}

async function subscriptionRequest(paymentMethodId) {
  let couponIdToSend = validatedDiscountCode.value;
  let discountPercentageToSend = percentageDiscount.value;

  if (isDynamicDiscount.value || discountPercentageToSend === 0) {
    couponIdToSend = "";
    discountPercentageToSend = 0;
  }

  const response = await buySeats(
    paymentMethodId,
    priceId.value,
    players.value,
    couponIdToSend,
    discountPercentageToSend
  );

  handleMessage("The subscription purchase was successful!", false);
  handleGTMEvent(response.data.subscription.id);
}

function handleGTMEvent(subscriptionId) {
  const subscriptionData = {
    id: subscriptionId,
    paymentAmount: totalPrice.value.toFixed(2),
    suscriptionPlan: "Month",
    players: players.value,
    discountCode: validatedDiscountCode.value,
    organizationCode: "",
    totalBeforeDiscount: subTotal.value.toFixed(2),
    discountAmount: totalDiscount.value.toFixed(2),
  };
  const userDetails = {
    email: user.value.email,
    firstName: user.value.firstName,
    lastName: user.value.lastName,
    category: "Facility",
  }

  triggerPurchaseEvent(
    subscriptionData,
    userDetails,
  );
}

async function applyDiscountCode() {
  checkDiscountCode.value = true;
  discountCodeErrorText.value = "";

  if (!discountCode.value) return;

  try {
    const discountData = await getStripeDiscountCode(discountCode.value);
    validatedDiscountCode.value = discountCode.value;
    isDynamicDiscount.value = discountData.is_dynamic;

    totalDiscount.value = 0;
    percentageDiscount.value = 0;

    if (
      discountData.is_dynamic &&
      discountData.expected_price != null &&
      discountData.expected_price <= price.value
    ) {
      const dynamicDiscount =
        ((price.value - discountData.expected_price) / price.value) * 100;
      const dynamicDiscountRounded = Number(dynamicDiscount);
      const dynamicPriceOff = (subTotal.value * dynamicDiscount) / 100;
      const dynamicPriceOffRounded = Number(dynamicPriceOff);

      applyDiscount(dynamicPriceOffRounded, dynamicDiscountRounded);
    } else if (!discountData.is_dynamic) {
      const staticDiscount = discountData.discount_percentage;
      const staticPriceOff = (subTotal.value * staticDiscount) / 100;

      applyDiscount(staticPriceOff, staticDiscount);
    }
  } catch (error) {
    validatedDiscountCode.value = "";
    discountCodeErrorText.value = "The discount code is invalid or inactive!";
    resetDiscount();
  } finally {
    checkDiscountCode.value = false;
  }
}

function applyDiscount(discountValue, discountPercentage) {
  totalDiscount.value = discountValue;
  percentageDiscount.value = discountPercentage;
  totalPrice.value = subTotal.value - discountValue;
}

function recalculateTotals() {
  subTotal.value = calculateTotalPrice(price.value, players.value);
  totalPrice.value = calculateTotalPrice(price.value, players.value);
}

function resetDiscount() {
  totalDiscount.value = 0;
  percentageDiscount.value = 0;
  totalPrice.value = subTotal.value;
}

function calculateTotalPrice(price, playersCount) {
  return price * playersCount;
}

async function resetPaymentMethod() {
  clearPaymentMethod();
  await nextTick();
  initializateStripeElements();
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}

watch(players, (newValue) => {
  if (newValue < 1) {
    newValue = 1;
    players.value = 1;
  }

  if (
    newValue >= prices.value.min_users_tier_2 &&
    newValue <= prices.value.max_users_tier_2
  ) {
    setTierPrice("tier_2");
  } else if (newValue > prices.value.max_users_tier_2) {
    setTierPrice("tier_3");
  } else {
    setTierPrice("tier_1");
  }

  subTotal.value = price.value * newValue;
  totalPrice.value = subTotal.value;

  if (discountCode.value) {
    applyDiscountCode();
  }
});

watch(discountCode, (newValue) => {
  discountCodeErrorText.value = "";
  if (newValue) {
    resetDiscount();
  }
});
</script>

<style scoped>
#b-pay:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #1d2e48;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  transform: scale(0);
  border: 0.4em solid #1d2e48;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;

  + .contendero {
    border-color: green;
  }
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
</style>
