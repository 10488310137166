export function triggerPurchaseEvent(subscriptionData, userDetails) {
  const {
    id,
    paymentAmount,
    suscriptionPlan,
    players,
    discountCode,
    organizationCode,
    totalBeforeDiscount,
    discountAmount,
  } = subscriptionData;
  const { email, category } = userDetails;

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "pay",
    paymentDetails: {
      transactionId: id,
      paymentAmount,
      currency: "USD",
      paymentMethod: "Credit Card",
      suscriptionPlan,
      players,
      discountCode,
      organizationCode,
      totalBeforeDiscount,
      discountAmount,
    },
    userDetails: {
      email,
      category,
    },
  });
}

export function triggerAccountRegisteredEvent(registrationMethod) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "account_registered",
    registrationMethod,
  });
}

export function triggerFormSubmissionEvent(firstName, lastName, email, phone) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "form_submission",
    form_name: "sign_up",
    email,
    phone,
    firstName,
    lastName,
  });
}
