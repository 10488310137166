<template>
  <div>
    <div class="flex justify-between mt-5 p-3 bg-grey-steps">
      <p class="font-semibold">Product</p>
      <p>Units</p>
      <p>Amount</p>
    </div>

    <div class="flex justify-between p-3">
      <p>Players</p>
      <p>{{ players }}</p>
      <p>${{ subTotal.toFixed(2) }}</p>
    </div>
    <div class="flex justify-between p-3">
      <p>Discount ({{ percentageDiscount.toFixed(2) }}%)</p>
      <p>-${{ totalDiscount.toFixed(2) }}</p>
    </div>
    <div class="flex justify-between mt-3 font-semibold text-2xl p-3">
      <p>Total</p>
      <h3>${{ totalPrice.toFixed(2) }}</h3>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "OrderSummary",
  props: {
    players: {
      type: Number,
      required: true,
    },
    subTotal: {
      type: Number,
      required: true,
    },
    totalDiscount: {
      type: Number,
      required: true,
    },
    percentageDiscount: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style scoped>
.line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
</style>
